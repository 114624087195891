import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import { SectionHeading as HeadingTitle, Subheading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as UserIcon } from "feather-icons/dist/icons/user.svg";
import { ReactComponent as TagIcon } from "feather-icons/dist/icons/tag.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-3.svg";

//import blogpost1 from "images/player/ROKXL-006-BLK.jpg";
//import blogpost2 from "images/player/ROKXL-006-BLK.jpg";
import blogpost3 from "images/player/ROKXL-006-BLK.jpg";


const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const ThreeColumn = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap`;
const Column = tw.div`mt-24 lg:w-1/3`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Card = tw.div`lg:mx-4 xl:mx-8 max-w-sm flex flex-col h-full`;
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-cover bg-center h-80 lg:h-64 rounded rounded-b-none`
]);

const Details = tw.div`p-6 rounded border-2 border-t-0 rounded-t-none border-dashed border-primary-100 flex-1 flex flex-col items-center text-center lg:block lg:text-left`;
const MetaContainer = tw.div`flex items-center`;
const Meta = styled.div`
  ${tw`text-secondary-100 font-medium text-sm flex items-center leading-none mr-6 last:mr-0`}
  svg {
    ${tw`w-4 h-4 mr-1`}
  }
`;

const Title = tw.h5`mt-4 leading-snug font-bold text-lg`;
const Description = tw.p`mt-2 text-sm text-secondary-100`;
const Link = styled(PrimaryButtonBase).attrs({as: "a"})`
  ${tw`inline-block mt-4 text-sm font-semibold`}
`

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`-z-10 absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;

export default ({
  subheading = "Reviews",
  heading = <>Our Customers  <span tw="text-primary-500">Love Us.</span></>,
  description = "Check out some of our amazing reviews blog posts.",


}) => {
  const blogPosts = [
    {
      imageSrc:
        "https://www.therockfather.com/media/k2/items/cache/a024bfe2d251f38095b6f98072329d73_L.jpg",
      author: "James Zahn",
      category: "Review",
      title: "The Rock 'N' Rolla UFO Turntable is Out of this World",
      description: "Whether you're new to vinyl or an old-school listener seeking a new turntable, the Rock 'N' Rolla UFO certainly warrants a look. It's one of 2018's biggest surprises!",
      url: "https://www.therockfather.com/news-and-reviews/grownups/music-news/musicforall/item/5989-review-the-rock-n-rolla-ufo-turntable-is-out-of-this-world"
    },
    {
      imageSrc:
        "https://www.goldminemag.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTY4NDE5MDIxNTM2MTc1Mjky/image-placeholder-title.webp",
      author: "Dave Thompson",
      category: "Review",
      title: "New vinyl sounds natural on this retro portable record player",
      description: "The Rock ’N’ Rolla Premium portable briefcase turntable has the heart and soul to make a nostalgic music fan’s wildest dream. And it's as modern as it is retro!",
      url: "https://www.goldminemag.com/articles/new-vinyl-sounds-natural-retro-portable-record-player"
    },
    {
      imageSrc: blogpost3,
      author: "Joseph Timmons",
      category: "Review",
      title: "Rock ‘N’ Rolla Saves Your Record Soul’a",
      description: "This 'Go Anywhere', Attractively-Priced Record Players Make Perfect Complement to the renewed love for Vinyl, 'Back to the future, a retro look with today's technology",
      url: "https://indiepulsemusic.com/2016/10/13/rocknrollasavesrecordcollectors/"
    }
  ];
  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <HeadingTitle>{heading}</HeadingTitle>
          <HeadingDescription>{description}</HeadingDescription>
        </HeadingInfoContainer>
        <ThreeColumn>
          {blogPosts.map((post, index) => (
            <Column key={index}>
              <Card>
                <Image imageSrc={post.imageSrc} />
                <Details>
                  <MetaContainer>
                    <Meta>
                      <UserIcon />
                      <div>{post.author}</div>
                    </Meta>
                    <Meta>
                      <TagIcon />
                      <div>{post.category}</div>
                    </Meta>
                  </MetaContainer>
                  <Title>{post.title}</Title>
                  <Description>{post.description}</Description>
                  <Link href={post.url}>Read Post</Link>
                </Details>
              </Card>
            </Column>
          ))}
        </ThreeColumn>
      </Content>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
