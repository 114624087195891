/* A simple iframe wrapper that makes 16:9 responsive embed */
import React from 'react';
//import "node_modules/video-react/dist/video-react.css"; // import css
import { Player,BigPlayButton } from 'video-react';
import VideoThumb from "images/player/video.jpg";

export default () => {
  return (
    <div>
    <Player
      playsInline
      poster={VideoThumb}
      src="https://www.myrocknrolla.com/assets/video/rocknrolla.mp4">
      <BigPlayButton position="center" />
      </Player>   
    </div>
  );
};
