import React from "react";
import tw from "twin.macro";
import {Container as ContainerBase } from "components/misc/Layouts.js"
import logo from "../../images/player/ROKiT-Orb-Black.png"; //Orb on White Background 

const Container = tw(ContainerBase)`-mx-1 -mb-1`
const Content = tw.div`max-w-screen-xl mx-auto py-1 lg:py-1`;

const Row = tw.div`flex items-center justify-center flex-col px-8`

const LogoContainer = tw.div`flex items-center justify-center md:justify-start `;
const LogoImg = tw.img`w-12 mx-1`;
const Line = tw.div`flex items-center bg-black w-96 h-px`;

export default () => {
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <Line></Line>
                <LogoImg src={logo} /> 
            <Line></Line>
          </LogoContainer>
        </Row>
      </Content>
    </Container>
  );
};