import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero from "components/hero/BackgroundAsImage.js";
import Features from "components/features/DashedBorderSixFeatures";

import MainFeature from "components/features/TwoColSingleFeatureWithStats2.js";
import MainFeature2 from "components/features/TwoColWithTwoFeaturesAndButtons.js";
import Blog from "components/blogs/ThreeColSimpleWithImageAndDashedBorder.js";
import FAQ from "components/faqs/SimpleWithSideImage.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import customerSupportIllustrationSrc from "images/customer-support-illustration.svg";
import DividerWhite from "components/decorations/orb-divider-OnWhite.js";
//import DividerBlack from "components/decorations/orb-divider-OnBlack.js";


export default () => (
  <AnimationRevealPage>
    <Hero />
    <MainFeature />
      <DividerWhite />
    <Features />
      <DividerWhite />
    <MainFeature2 />
      <DividerWhite />
    <Blog />
    <DividerWhite />
    <FAQ
      imageSrc={customerSupportIllustrationSrc}
      imageContain={true}
      imageShadow={true}
      subheading="FAQs"
      heading={
        <>
          Do you have <span tw="text-primary-500">Questions ?</span>
        </>
      }
    />
    <DividerWhite />
    <ContactUsForm />
    <Footer />
  </AnimationRevealPage>
);
